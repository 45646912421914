import { useEffect, useState } from "react";
import moment from "moment-timezone";
import EvercleanBackendConnection from "../backendConnections/EvercleanBackendConnection";
import { IconWithTooltip } from "../components/IconWithTooltip";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import EnvVars from "../helpers/EnvVars";

function DefaultFooter() {
  const [backendVersion, setBackendVersion] = useState("");
  const [backendEnv, setBackendEnv] = useState("");

  const { t } = useTranslation();
  useEffect(() => {
    EvercleanBackendConnection.getData<{ version: string; env: string }>(
      "version"
    ).then((data) => {
      if (data) {
        setBackendEnv(data.env);
        setBackendVersion(data.version);
      }
    });
  }, []);
  return (
    <Container fluid>
      <Row className=" footer">
        <Col xs={4}>
          <span>
            {backendVersion && backendVersion !== EnvVars.PLATFORM_VERSION ? (
              <IconWithTooltip
                className="fas fa-exclamation-triangle mr-1"
                title={`Frontend and backend versions don't match (${backendVersion}). You should reload this page. (${backendEnv})`}
              />
            ) : null}
            Everclean Cloud Platform {EnvVars.PLATFORM_VERSION} &copy;{" "}
            {moment().year() + " "}
          </span>
        </Col>
        <Col xs={4} className="d-flex justify-content-center">
          <span>Powered by&nbsp; </span>
          <a href="https://www.droople.com">Droople</a>
        </Col>
        <Col xs={4} className="d-flex justify-content-end">
          <span>{t("footer.for_support")}&nbsp;</span>

          <a href="mailto:support@droople.com">{t("footer.here")}</a>
        </Col>
      </Row>
    </Container>
  );
}

export default DefaultFooter;
