import EvercleanBackendConnection from "../backendConnections/EvercleanBackendConnection";
import { UserNotification } from "./Interfaces";

export default class FetchResources {
  static getRecursivelyNotifications(
    limit: number,
    offset: number,
    results?: UserNotification[]
  ): Promise<UserNotification[] | undefined> {
    if (results === undefined) {
      results = [];
    }

    return EvercleanBackendConnection.getDataWithParams<UserNotification[]>(
      {
        limit: limit,
        offset: offset,
      },
      "notifications"
    )
      .then((data) => {
        if (data) {
          if (data.length > 0) {
            results = results!.concat(data);

            if (data.length === limit) {
              return this.getRecursivelyNotifications(
                limit,
                offset + limit,
                results
              );
            }
          }
        }
        return results;
      })
      .catch((err) => {
        throw err;
      });
  }
}
