import { useEffect, useState } from "react";
import { Button, Card, Col, Container, FormCheck, Row } from "react-bootstrap";
import filterFactory, {
  dateFilter,
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import moment from "moment-timezone";
import EvercleanBackendConnection from "../../backendConnections/EvercleanBackendConnection";
import { isStrictlyDefined } from "../../helpers/Helpers";
import { UserNotification } from "../../helpers/Interfaces";
import FetchResources from "../../helpers/FetchResources";

export default function Notifications() {
  const { i18n, t } = useTranslation();

  const [notifications, setNotifications] = useState<UserNotification[]>([]);
  const [notificationsToDisplay, setNotificationsToDisplay] = useState<
    UserNotification[]
  >([]);
  const [notificationsCount, setNotificationsCount] = useState<number>(0);
  const [hasError, setHasError] = useState<boolean>(false);
  const [showReadNotifications, setShowReadNotifications] =
    useState<boolean>(false);

  useEffect(() => {
    FetchResources.getRecursivelyNotifications(1000, 0)
      .then((data) => {
        if (data) {
          setNotifications(data);

          const displayNotifications = data.filter((un) => !un.is_read);
          setNotificationsToDisplay(displayNotifications);
          setNotificationsCount(displayNotifications.length);
        }
      })
      .catch((err) => {
        console.error(err);
        setHasError(true);
      });
  }, []);

  async function toggleIsRead(un: UserNotification, currentValue: boolean) {
    return await EvercleanBackendConnection.patch<UserNotification>(
      { is_read: !currentValue },
      "notifications",
      un.id
    ).catch((err) => {
      console.error(err);
      window.alert(t("notifications.notification_update_error"));
    });
  }

  const columns: ColumnDescription[] = [
    {
      dataField: "notification.datetime",
      text: t("notifications.notification_datetime"),
      formatter: (cell: string) =>
        moment(cell).locale(i18n.language).format("lll"),
      sort: true,
      filter: isMobile ? undefined : dateFilter({}),
    },
    {
      dataField: "notification.category",
      text: t("notifications.notification_category"),
      formatter: (cell: string) => cell.toUpperCase(),
      sort: true,
      filter: isMobile
        ? undefined
        : selectFilter({
            placeholder: t("notifications.select_category"),
            options: {
              scheduled_cleaning: "SCHEDULED_CLEANING",
              missed_cleaning: "MISSED_CLEANING",
              test: "TEST",
            },
          }),
    },
    {
      dataField: "notification.entity",
      text: t("notifications.notification_target"),
      formatter: (cell: any) => {
        if (isStrictlyDefined(cell.space)) {
          return (
            <>
              <b>Space: </b> {cell.space.name}
            </>
          );
        }
        return "";
      },
      sort: true,
      filter: isMobile
        ? undefined
        : textFilter({
            placeholder: t("notifications.search"),
          }),
      filterValue: (cell: any, row: any) => {
        if (isStrictlyDefined(cell.space)) {
          return "Space " + cell.space.name;
        }
        return "";
      },
    },
    {
      dataField: "text",
      text: t("notifications.notification_message"),
      sort: true,
      filter: isMobile
        ? undefined
        : textFilter({
            placeholder: t("notifications.search"),
          }),
    },
    {
      dataField: "is_read",
      text: t("notifications.notification_read"),
      formatter: (cell: boolean, row: UserNotification) => {
        return (
          <FormCheck
            type="checkbox"
            defaultChecked={cell}
            onChange={() => toggleIsRead(row, cell)}
            label={t("notifications.notification_is_read")}
          />
        );
      },
      sort: true,
    },
  ];

  return hasError ? (
    <Container className="animated fadeIn">
      <Row className="text-center mt-5">
        <Col ml="2" mr="2">
          <h5>{t("notifications.could_not_fetch_notifications")}</h5>
        </Col>
      </Row>
    </Container>
  ) : (
    <Container fluid className="animated fadeIn">
      <ToolkitProvider
        bootstrap4={true}
        keyField="id"
        data={notificationsToDisplay}
        columns={columns}
      >
        {(props) => (
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div>
                <i className="fal fa-bell" />{" "}
                {t("notifications.your_notifications")}
              </div>
              <div>
                <span>
                  {t("notifications.count_notifications_found", {
                    count: notificationsCount,
                  })}
                </span>
                <span className="ms-3">
                  <Button
                    onClick={() => {
                      // Checking the value before we toggle it, so the new value will be the opposite.
                      if (showReadNotifications) {
                        const displayNotifications = notifications.filter(
                          (un) => !un.is_read
                        );
                        setNotificationsToDisplay(displayNotifications);
                        setNotificationsCount(displayNotifications.length);
                      } else {
                        setNotificationsToDisplay(notifications);
                        setNotificationsCount(notifications.length);
                      }
                      setShowReadNotifications(!showReadNotifications);
                    }}
                  >
                    {showReadNotifications
                      ? t("notifications.hide_read_notifications")
                      : t("notifications.show_read_notifications")}
                  </Button>
                </span>
              </div>
            </Card.Header>
            <Card.Body>
              <BootstrapTable
                striped
                hover
                condensed
                wrapperClasses="table-responsive"
                bordered={false}
                onDataSizeChange={({ dataSize }) => {
                  setNotificationsCount(dataSize);
                }}
                filter={filterFactory()}
                pagination={paginationFactory({
                  sizePerPage: 30,
                })}
                {...props.baseProps}
              />
            </Card.Body>
          </Card>
        )}
      </ToolkitProvider>
    </Container>
  );
}
