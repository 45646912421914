import React from "react";
import "./App.scss";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import FullScreenLoader from "./components/FullScreenLoader";
import { PrivateWrapper } from "./helpers/withAuth";
import DefaultLayout from "./containers/DefaultLayout";
import QRLayout from "./containers/QRLayout";
import Notifications from "./views/Notifications/Notifications";

const Login = React.lazy(() => import("./views/Auth/Login"));
const Logout = React.lazy(() => import("./views/Auth/Logout"));
const Page404 = React.lazy(() => import("./views/Page404/Page404"));
const Calendar = React.lazy(() => import("./views/QR/Calendar"));
const SpaceCleaningSchedule = React.lazy(
  () => import("./views/QR/SpaceCleaningSchedule")
);
const Feedback = React.lazy(() => import("./views/QR/Feedback"));
const Password = React.lazy(() => import("./views/QR/Password"));
const ScanError = React.lazy(() => import("./views/QR/ScanError"));
const RecordingError = React.lazy(() => import("./views/QR/RecordingError"));
const RecordingSuccess = React.lazy(
  () => import("./views/QR/RecordingSuccess")
);

const Spaces = React.lazy(() => import("./views/Spaces/Spaces"));
const SingleSpace = React.lazy(() => import("./views/Spaces/SingleSpace"));

function App() {
  return (
    <Router>
      <React.Suspense fallback={<FullScreenLoader />}>
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="logout" element={<Logout />} />
          <Route path="404" element={<Page404 />} />
          <Route path="qr" element={<QRLayout />}>
            <Route index element={<Page404 />} />
            <Route path="calendar" element={<Calendar />} />
            <Route
              path="spaceCleaningSchedule"
              element={<SpaceCleaningSchedule />}
            />
            <Route path="password" element={<Password />} />
            <Route path="feedback" element={<Feedback />} />
            <Route path="scanError" element={<ScanError />} />
            <Route path="recordingSuccess" element={<RecordingSuccess />} />
            <Route path="recordingError" element={<RecordingError />} />
          </Route>
          <Route path="" element={<PrivateWrapper />}>
            <Route path="" element={<DefaultLayout />}>
              <Route path="spaces/:spaceId" element={<SingleSpace />} />
              <Route path="spaces" element={<Spaces />} />
              <Route path="notifications" element={<Notifications />} />
            </Route>
          </Route>
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </React.Suspense>
    </Router>
  );
}

export default App;
