import axios from "axios";
import AuthService from "../helpers/AuthService";
import EnvVars from "../helpers/EnvVars";
import { User } from "../helpers/Interfaces";
type ResourceName =
  | "records"
  | "notifications"
  | "version"
  | "usage"
  | "cleaning_spaces"
  | "cleaning_space_id"
  | "public_cleaning_spaces"
  | "public_cleaning_space"
  | "cleaning_schedules"
  | "buildings"
  | "building_schedules";
export const everclean_api_instance = axios.create({
  baseURL: EnvVars.EVERCLEAN_API_GATEWAY_URL,
});
export class ErrorWithCode extends Error {
  code?: string | number;
  constructor(message: string | undefined, code?: string | number) {
    super(message);
    this.code = code;
  }
}
export default class EvercleanBackendConnection {
  static isUserAnonymous(user?: User): boolean {
    return !!(
      !user?.id ||
      user?.email.toLowerCase() === "droople.demo@gmail.com" ||
      user?.email.toLowerCase() === "droople.demo+filtration@gmail.com"
    );
  }

  static forbidAnonymousUser(user?: User) {
    if (EvercleanBackendConnection.isUserAnonymous(user)) {
      throw new Error("Operation forbidden to anonymous users.");
    }
  }

  static get<T>(resourceName: ResourceName, ...path: (string | number)[]) {
    return everclean_api_instance
      .get<{ code?: string; message?: string; data?: T }>(
        "/" + resourceName + (path.length > 0 ? "/" + path.join("/") : ""),
        { headers: { Authorization: `Bearer ${AuthService.getToken()}` } }
      )
      .then((res) => {
        return res.data;
      });
  }
  static getData<T>(
    resourceName: ResourceName,
    ...path: (string | number)[]
  ): Promise<T | undefined> {
    return EvercleanBackendConnection.get<T>(resourceName, ...path).then(
      (_) => _.data
    );
  }
  static getWithParams<T>(
    params: object,
    resourceName: ResourceName,
    token?: string,
    ...path: (string | number)[]
  ) {
    return everclean_api_instance
      .get<{ code?: string; message?: string; data?: T }>(
        "/" + resourceName + (path.length > 0 ? "/" + path.join("/") : ""),
        {
          headers: {
            Authorization: token ?? `Bearer ${AuthService.getToken()}`,
          },
          params: params,
        }
      )
      .then((res) => {
        if (res.data.code) {
          const err = new ErrorWithCode(res.data.message, res.data.code);
          console.error(`Error #${res.data.code}: ${res.data.message}`);
          throw err;
        }
        return res.data;
      });
  }
  static getDataWithParams<T>(
    params: object,
    resourceName: ResourceName,
    token?: string,
    ...path: (string | number)[]
  ) {
    return EvercleanBackendConnection.getWithParams<T>(
      params,
      resourceName,
      token,
      ...path
    ).then((_) => _.data);
  }

  static async update<T>(
    data: T,
    resourceName: ResourceName,
    ...path: (string | number)[]
  ) {
    EvercleanBackendConnection.forbidAnonymousUser(
      await AuthService.getProfile()
    );
    return everclean_api_instance
      .put<{ code?: string; message?: string; data?: T }>(
        "/" + resourceName + (path.length > 0 ? "/" + path.join("/") : ""),
        data,
        { headers: { Authorization: `Bearer ${AuthService.getToken()}` } }
      )
      .then((res) => {
        if (res.data.code) {
          const err = new ErrorWithCode(res.data.message, res.data.code);
          console.error(`Error #${res.data.code}: ${res.data.message}`);
          throw err;
        }
        return res.data;
      });
  }

  static async patch<T>(
    data: Partial<T>,
    resourceName: ResourceName,
    ...path: (string | number)[]
  ) {
    EvercleanBackendConnection.forbidAnonymousUser(
      await AuthService.getProfile()
    );
    return everclean_api_instance
      .patch<{ code?: string; message?: string }>(
        "/" + resourceName + (path.length > 0 ? "/" + path.join("/") : ""),
        data,
        { headers: { Authorization: `Bearer ${AuthService.getToken()}` } }
      )
      .then((res) => {
        if (res.data.code) {
          const err = new ErrorWithCode(res.data.message, res.data.code);
          console.error(`Error #${res.data.code}: ${res.data.message}`);
          throw err;
        }
        return res.data;
      });
  }

  static async post<T>(
    data: T,
    resourceName: ResourceName,
    ...path: (string | number)[]
  ) {
    EvercleanBackendConnection.forbidAnonymousUser(
      await AuthService.getProfile()
    );
    return EvercleanBackendConnection.postAllowAnonymous(
      data,
      resourceName,
      undefined,
      ...path
    );
  }

  static async postAllowAnonymous<T>(
    data: T,
    resourceName: ResourceName,
    token?: string,
    ...path: (string | number)[]
  ) {
    return everclean_api_instance
      .post<{ code?: string; message?: string; data: T }>(
        "/" + resourceName + (path.length > 0 ? "/" + path.join("/") : ""),
        data,
        {
          headers: {
            Authorization: token ?? `Bearer ${AuthService.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.code) {
          const err = new ErrorWithCode(res.data.message, res.data.code);
          console.error(`Error #${res.data.code}: ${res.data.message}`);
          throw err;
        }
        return res.data.data;
      });
  }

  static async delete(
    resourceName: ResourceName,
    ...path: (string | number)[]
  ) {
    EvercleanBackendConnection.forbidAnonymousUser(
      await AuthService.getProfile()
    );
    const res = await everclean_api_instance.delete(
      `/${[resourceName, ...path].join("/")}`,
      {
        headers: {
          Authorization: `Bearer ${AuthService.getToken()}`,
        },
      }
    );
    if (res.data.code) {
      const err = new ErrorWithCode(res.data.message, res.data.code);
      console.error(`Error #${res.data.code}: ${res.data.message}`);
      throw err;
    }
  }
}
