import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Col, Container, Dropdown, DropdownButton, Row } from "react-bootstrap";

import vebego_logo from "../img/brand/vebego_logo.png";
import everclean_logo from "../img/brand/everclean_logo.png";
import global_facilities_logo from "../img/brand/global_facilities_logo.png";
import { changeLanguage } from "i18next";
import { useEffect, useState } from "react";
import { AVAILABLE_LANGUAGES } from "../i18n";

function CleanerLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState("");
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  const [params] = useSearchParams();

  const space = params.get("s");

  return (
    <>
      <Container fluid>
        <Row className="d-flex justify-content-end p-2">
          <Col xs={2}></Col>
          <Col xs={8}>
            <div className="d-flex flex-row justify-content-center mt-5 mb-5">
              <img
                src={space === "89" ? global_facilities_logo : vebego_logo}
                alt="Vebego"
                width={space === "89" ? "180vh" : "100vh"}
              ></img>
            </div>
          </Col>
          <Col xs={2}>
            <DropdownButton
              title={<i className="fa-solid fa-language"></i>}
              size="sm"
              align="end"
            >
              {AVAILABLE_LANGUAGES.map((language) => {
                return (
                  <Dropdown.Item
                    onClick={() => {
                      changeLanguage(language.id);
                    }}
                    key={language.id + "_options"}
                  >
                    {language.name}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
          </Col>
        </Row>
      </Container>
      <div className="page-content">
        <Outlet />
      </div>
      <Container fluid>
        <Row className="d-flex justify-content-end p-2">
          <Col xs={4}>
            {space && !currentPath.includes("spaceCleaningSchedule") ? (
              currentPath.includes("calendar") ? (
                <i
                  className="fa-solid fa-arrow-left"
                  onClick={() => navigate(-1)}
                />
              ) : (
                <i
                  className="fal fa-calendar-alt"
                  onClick={() => navigate("calendar?s=" + space)}
                />
              )
            ) : null}
          </Col>
          <Col xs={4} className="d-flex justify-content-center">
            <img src={everclean_logo} alt="Everclean" width={"100vh"}></img>
          </Col>
          <Col xs={4}>
            <a href="mailto:support@droople.com">
              <i className="fal fa-envelope float-end" />
            </a>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CleanerLayout;
