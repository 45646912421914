import moment from "moment-timezone";
import { Client, Params } from "./Interfaces";

/**
 * Generates a deterministic hash from a string
 * @param s the string
 */
export function hashCode(s: string) {
  let h = 0;
  for (let i = 0; i < s.length; i++)
    h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;

  return h;
}
/**
 * Short-hand version for JSON stringify & parse. Note that classes get transformed into objects and lose their functions.
 * @param obj
 * @returns
 */
export function cloneObject<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * Returns **false** if object is `null` or `undefined`, **true** otherwise.
 * Unlike isDefined, isStrictlyDefined tells the compiler that the object is defined.
 */
export function isStrictlyDefined<
  T extends object | string | number | bigint | boolean | symbol
>(obj: T | undefined | null): obj is T {
  return obj !== null && obj !== undefined;
}

export const onlyUniqueFilter = (value: any, index: number, self: any[]) =>
  self.indexOf(value) === index;

export function flattenObject(obj: any, prefix = "") {
  const flattened: any = {};
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      Object.assign(flattened, flattenObject(obj[key], key));
    } else {
      flattened[(prefix !== "" ? prefix + "_" : "") + key] = obj[key];
    }
  });
  return flattened;
}
export function downloadCSV<T extends { [k: string]: any }>(
  filename: string,
  arr: T[]
) {
  if (arr.length === 0) {
    return;
  }
  const arrWithoutObject = arr.map((_) => flattenObject(_));

  const keys = arrWithoutObject
    .flatMap((row) => Object.keys(row))
    .filter(onlyUniqueFilter);
  const rows = arrWithoutObject
    .map((obj) =>
      keys
        .map((k) => {
          let row =
            obj[k] !== undefined ? ("" + obj[k]).replace(/"/g, '""') : "";
          if (row.search(/("|,|\n)/g) >= 0) {
            row = '"' + row + '"';
          }
          return row;
        })
        .join(",")
    )
    .join("\n");
  const cleanKeys = keys
    .map((k) => {
      let row = k.replace(/"/g, '""');
      if (row.search(/("|,|\n)/g) >= 0) {
        row = '"' + row + '"';
      }
      return row;
    })
    .join(",");

  // const csvContent = "data:text/csv;charset=utf-8," + keys.join(',') + "\n" + rows;
  const csvFile = cleanKeys + "\n" + rows;
  var blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    var url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
/**
 * Better alternative to moment.max that discards undefined and null values and returns undefined in case of empty array.
 * @param moments
 */
export function momentMax(
  moments: (string | moment.Moment | null | undefined | Date)[]
) {
  const valid_moments = moments
    .map((_) => (typeof _ === "string" || _ instanceof Date ? moment(_) : _))
    .filter((_) => _ && _.isValid()) as moment.Moment[];
  return valid_moments.length ? moment.max(valid_moments) : undefined;
}

/**
 * Better alternative to moment.min that discards undefined and null values and returns undefined in case of empty array.
 * @param moments
 */
export function momentMin(
  moments: (string | moment.Moment | null | undefined | Date)[]
) {
  const valid_moments = moments
    .map((_) => (typeof _ === "string" || _ instanceof Date ? moment(_) : _))
    .filter((_) => _ && _.isValid()) as moment.Moment[];
  return valid_moments.length ? moment.min(valid_moments) : undefined;
}
/**
 * Merge all available parameters from the source entity, fetching the client and its parent parameters as well, but preferring parameters closest to the source.
 * @param source
 * @returns Params
 */
export function getParams(
  source?: {
    params?: Params | null;
    client?: Client | null;
    parent?: Client | null;
  } | null
): Params {
  const params: Params = {};
  if (source?.parent) {
    // source is a client with parent
    Object.assign(params, getParams(source.parent));
  }
  if (source?.client) {
    // source is a user or other entity belonging to a client
    Object.assign(params, getParams(source.client));
  }
  if (source?.params) {
    // source is a client or a user
    Object.assign(params, source.params);
  }
  return params;
}

export function round2(num: number) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}
