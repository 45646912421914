import React, { Component } from "react";
import { Spinner } from "react-bootstrap";

class FullScreenLoader extends Component {
  render() {
    return (
      <div className="animated fadeIn pt-1 h-100 row align-items-center justify-content-center">
        <Spinner animation={"border"} />
      </div>
    );
  }
}

export default FullScreenLoader;
