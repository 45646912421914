import axios from "axios";
import AuthService from "../helpers/AuthService";
import EnvVars from "../helpers/EnvVars";
import { User } from "../helpers/Interfaces";
type ResourceName =
  | "devices"
  | "assets"
  | "clients"
  | "users"
  | "telemetry"
  | "consumables"
  | "sensors"
  | "models"
  | "gateways"
  | string;
axios.defaults.baseURL = EnvVars.MAIN_PLATFORM_API_GATEWAY_URL;

export class ErrorWithCode extends Error {
  code?: string | number;
  constructor(message: string | undefined, code?: string | number) {
    super(message);
    this.code = code;
  }
}
export default class MainPlatformAPI {
  static isUserAnonymous(user?: User): boolean {
    return !!(
      !user?.id ||
      user?.email.toLowerCase() === "droople.demo@gmail.com" ||
      user?.email.toLowerCase() === "droople.demo+filtration@gmail.com"
    );
  }

  static forbidAnonymousUser(user?: User) {
    if (MainPlatformAPI.isUserAnonymous(user)) {
      throw new Error("Operation forbidden to anonymous users.");
    }
  }

  static get<T>(resourceName: ResourceName, ...path: (string | number)[]) {
    return axios
      .get<{ code?: string; message?: string; data?: T }>(
        "/" + resourceName + (path.length > 0 ? "/" + path.join("/") : ""),
        { headers: { Authorization: `Bearer ${AuthService.getToken()}` } }
      )
      .then((res) => {
        return res.data;
      });
  }

  static getData<T>(
    resourceName: ResourceName,
    ...path: (string | number)[]
  ): Promise<T | undefined> {
    return MainPlatformAPI.get<T>(resourceName, ...path).then((_) => _.data);
  }

  static getWithParams<T>(
    params: object,
    resourceName: ResourceName,
    ...path: (string | number)[]
  ) {
    return axios
      .get<{ code?: string; message?: string; data?: T }>(
        "/" + resourceName + (path.length > 0 ? "/" + path.join("/") : ""),
        {
          headers: { Authorization: `Bearer ${AuthService.getToken()}` },
          params: params,
        }
      )
      .then((res) => {
        return res.data;
      });
  }
  static getDataWithParams<T>(
    params: object,
    resourceName: ResourceName,
    ...path: (string | number)[]
  ) {
    return MainPlatformAPI.getWithParams<T>(params, resourceName, ...path).then(
      (_) => _.data
    );
  }
  static getDataWithParamsAndRetries<T>(
    retries: number,
    params: object,
    resourceName: ResourceName,
    ...path: (string | number)[]
  ): Promise<T | undefined> {
    return MainPlatformAPI.getWithParams<T>(params, resourceName, ...path)
      .then((_) => _.data)
      .catch((err) => {
        if (retries > 0) {
          console.warn(err);
          console.warn("Retrying...");
          return MainPlatformAPI.getDataWithParamsAndRetries<T>(
            retries - 1,
            params,
            resourceName,
            ...path
          );
        } else {
          throw err;
        }
      });
  }

  static getUserByEmail(email: string) {
    return MainPlatformAPI.getWithParams<User>({ email }, "users");
  }
}
