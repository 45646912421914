import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import i18n from "i18next";
import moment from "moment-timezone";
import "moment/locale/fr";
import "moment/locale/de";
import "moment/locale/it";
i18n.on("languageChanged", (lng) => {
  document.documentElement.setAttribute("lang", lng);
  moment.locale(lng);
});
moment.locale(i18n.language);
document.documentElement.setAttribute("lang", i18n.language);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
