const EnvVars = {
  AWS_REGION: process.env.REACT_APP_AWS_REGION || "",
  AWS_COGNITO_CLIENT_ID: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID || "",
  AWS_COGNITO_USER_POOL_ID:
    process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID || "",
  MAIN_PLATFORM_API_GATEWAY_URL:
    process.env.REACT_APP_MAIN_PLATFORM_API_GATEWAY_URL || "",
  MAIN_PLATFORM_URL: process.env.REACT_APP_MAIN_PLATFORM_URL || "",
  EVERCLEAN_API_GATEWAY_URL:
    process.env.REACT_APP_EVERCLEAN_API_GATEWAY_URL || "",
  PLATFORM_VERSION: process.env.REACT_APP_PLATFORM_VERSION || "",
};
export default EnvVars;
