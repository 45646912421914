import DefaultFooter from "./DefaultFooter";
import DefaultHeader from "./DefaultHeader";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

function DefaultLayout() {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState("");
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);
  return (
    <>
      {currentPath === "/" ? <Navigate to="spaces" replace /> : null}
      <DefaultHeader />
      <div className="page-content">
        <Outlet />
      </div>
      <DefaultFooter />
    </>
  );
}

export default DefaultLayout;
