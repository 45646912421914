import { Navigate, Outlet, useLocation } from "react-router-dom";
import AuthService from "./AuthService";
export const PrivateWrapper = () => {
  const auth = AuthService.loggedIn();
  const location = useLocation();
  return auth ? (
    <Outlet />
  ) : (
    <Navigate
      to={"/login?redirect=" + encodeURIComponent(location.pathname)}
      replace={true}
    />
  );
};
