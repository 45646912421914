import React, { ReactNode } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { hashCode } from "../helpers/Helpers";
import ReactDOMServer from "react-dom/server";

type IconWithTooltipProps = {
  className: string;
  title: string | ReactNode;
  style?: React.CSSProperties;
  color?: string;
  id?: string;
  placement?: "top" | "right" | "bottom" | "left";
  float?: "right" | "left";
};

export const IconWithTooltip = ({
  className,
  style,
  id,
  title,
  color,
  placement,
  float,
}: IconWithTooltipProps) => {
  const computedStyle = Object.assign(style || {}, color ? { color } : {});
  const computedId = id
    ? id
    : "icon-with-tooltip-" +
      hashCode(ReactDOMServer.renderToString(<>{title}</>) || "");
  return (
    <OverlayTrigger
      placement={placement}
      overlay={<Tooltip id={computedId + "tooltip"}>{title}</Tooltip>}
    >
      <span className={float ? "float-" + float : ""}>
        <i className={className} style={computedStyle} id={computedId} />
      </span>
    </OverlayTrigger>
  );
};
