import decode from "jwt-decode";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { User } from "./Interfaces";
import MainPlatformAPI from "../backendConnections/MainPlatformBackendConnection";
import { getParams } from "./Helpers";
import EnvVars from "./EnvVars";

/**
 * https://hptechblogs.com/using-json-web-token-react/
 */
export default class AuthService {
  private static currentUser: User | undefined = undefined;

  private static localStorageFallback: {
    id_token?: string;
    invitation_key?: string;
    invitation_client_id?: number;
  } = {
    id_token: undefined,
  };

  static loggedIn() {
    // Checks if there is a saved token and it's still valid
    const rawToken = AuthService.getToken();
    if (!rawToken) return false;
    const token: { exp: number } = decode(AuthService.getToken()); // Getting token from localstorage
    return !!token && !AuthService.isTokenExpired(token); // handwaiving here
  }

  static isTokenExpired(token: { exp: number }) {
    try {
      return token.exp < Date.now() / 1000;
    } catch (err: any) {
      return false;
    }
  }

  static setToken(idToken: AmazonCognitoIdentity.CognitoIdToken) {
    // Saves user token to localStorage
    try {
      localStorage.setItem("id_token", idToken.getJwtToken());
    } catch (e) {
      console.warn(
        "Cannot save credentials to local storage. Using temporary variable instead."
      );
      AuthService.localStorageFallback.id_token = idToken.getJwtToken();
    }
  }

  static getToken(): string {
    // Retrieves the user token from localStorage
    try {
      return localStorage.getItem("id_token") || "";
    } catch (e) {
      console.warn(
        "Cannot read credentials from local storage. Using temporary variable instead."
      );
      return AuthService.localStorageFallback.id_token || "";
    }
  }

  static logout() {
    // Clear user token and profile data from localStorage
    localStorage.removeItem("id_token");
    AuthService.localStorageFallback.id_token = undefined;
    if (AuthService.currentUser) AuthService.currentUser = undefined;

    const currentUser = AuthService.userPool.getCurrentUser();
    if (currentUser) currentUser.signOut();
  }

  static async getProfile(): Promise<User> {
    if (AuthService.currentUser)
      return Promise.resolve(AuthService.currentUser);
    else {
      const token = AuthService.getToken();
      if (!token) throw Error("User not logged in");
      const decodedToken: any = decode(token);
      const email = decodedToken.email?.toLowerCase();
      return MainPlatformAPI.getUserByEmail(email).then((res) => {
        if (res.data && res.data) {
          const user = res.data;
          AuthService.currentUser = user;
          return user;
        } else {
          throw new Error(
            "User is not provisioned in the system. Please contact your administrator to receive access."
          );
        }
      });
    }
  }

  ///////////////////////////////////////////////
  static poolData: AmazonCognitoIdentity.ICognitoUserPoolData = {
    UserPoolId: EnvVars.AWS_COGNITO_USER_POOL_ID,
    ClientId: EnvVars.AWS_COGNITO_CLIENT_ID,
  };

  static userPool = new AmazonCognitoIdentity.CognitoUserPool(
    AuthService.poolData
  );

  // Initializing important variables
  constructor() {
    if (AuthService.userPool.getCurrentUser()) {
      AuthService._fetchCurrentAuthToken();
    }
  }

  /*
   * Cognito User Pool functions
   */

  static _fetchCurrentAuthToken(): Promise<AmazonCognitoIdentity.CognitoIdToken> {
    return new Promise((resolve, reject) => {
      var cognitoUser = AuthService.userPool.getCurrentUser();

      if (cognitoUser) {
        cognitoUser.getSession(function sessionCallback(
          err: Error,
          session: any
        ) {
          if (err) {
            reject(err);
          } else if (!session.isValid()) {
            reject(new Error("Invalid Session"));
          } else {
            const cognitoToken = session.getIdToken();
            AuthService.setToken(cognitoToken);
            resolve(cognitoToken);
          }
        });
      } else {
        reject(new Error("No current Cognito user"));
      }
    });
  }

  static register(
    name: string,
    email: string,
    password: string,
    onSuccess: any,
    onFailure: any
  ) {
    var dataEmail = {
      Name: "email",
      Value: email.toLowerCase(),
    };
    var attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(
      dataEmail
    );

    var dataName = {
      Name: "name",
      Value: name,
    };
    var attributeName = new AmazonCognitoIdentity.CognitoUserAttribute(
      dataName
    );

    AuthService.userPool.signUp(
      AuthService.toUsername(email),
      password,
      [attributeEmail, attributeName],
      [],
      (err, res) => {
        if (!err) {
          onSuccess(res);
        } else {
          onFailure(err);
        }
      }
    );
  }

  static signin(
    email: string,
    password: string,
    onSuccess: any,
    onFailure: any
  ) {
    var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
      {
        Username: AuthService.toUsername(email),
        Password: password,
      }
    );

    var cognitoUser = AuthService.createCognitoUser(email);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (res: AmazonCognitoIdentity.CognitoUserSession) => {
        const token = res;
        AuthService.setToken(token.getIdToken()); // Setting the token in localStorage
        AuthService.getProfile()
          .then((profile) => {
            if (!getParams(profile).everclean_feature) {
              const error: any = new Error(
                "User profile doesn't have access to this service"
              );
              error.code = "ClientNotAuthorized";
              throw error;
            }
            onSuccess(token);
          })
          .catch((err) => {
            console.error(err);
            AuthService.logout();
            onFailure(err);
          });
      },
      onFailure: onFailure,
    });
  }

  static createCognitoUser(email: string) {
    return new AmazonCognitoIdentity.CognitoUser({
      Username: AuthService.toUsername(email),
      Pool: AuthService.userPool,
    });
  }

  static toUsername(email: string) {
    return email.replace("@", "-at-").toLowerCase();
  }
}
