import { useEffect, useState } from "react";
import {
  Container,
  Dropdown,
  DropdownButton,
  Navbar,
  NavbarBrand,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AuthService from "../helpers/AuthService";
import { AVAILABLE_LANGUAGES } from "../i18n";
import everclean_logo from "../img/brand/everclean_logo.png";
import EnvVars from "../helpers/EnvVars";
import EvercleanBackendConnection from "../backendConnections/EvercleanBackendConnection";
import { UserNotification } from "../helpers/Interfaces";

function DefaultHeader() {
  const navigate = useNavigate();

  const { i18n, t } = useTranslation();
  const [email, setEmail] = useState("");
  const [notifications, setNotifications] = useState<UserNotification[]>([]);
  const [notificationsCount, setNotificationsCount] = useState<number>(0);

  useEffect(() => {
    AuthService.getProfile().then((_) => setEmail(_.email));
  }, []);

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    EvercleanBackendConnection.getDataWithParams<UserNotification[]>(
      {
        limit: 11,
        only_unread: true,
      },
      "notifications"
    )
      .then((data) => {
        if (data) {
          setNotifications(data);
          setNotificationsCount(data.length);
        }
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <Navbar expand="lg" className="bg-light">
      <Container fluid>
        <NavbarBrand>
          {" "}
          <img
            src={everclean_logo}
            onClick={() => navigate("/spaces")}
            alt=""
            height="30vh"
            style={{ cursor: "pointer" }}
          />{" "}
        </NavbarBrand>

        <Dropdown className="justify-content-end">
          <Dropdown.Toggle>{email}</Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                window.location.href = `${
                  EnvVars.MAIN_PLATFORM_URL
                }/login?email=${encodeURIComponent(email)}`;
              }}
            >
              <i className="fas fa-laptop" /> {t("header.main_platform")}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                AuthService.logout();
                navigate(0); // Refresh the page
              }}
            >
              <i className="fal fa-sign-out" /> {t("buttons.logout")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <div className="d-flex">
          <DropdownButton
            title={
              <i className="fa fa-bell">
                <span style={{ fontSize: 12 }}>
                  {" "}
                  ({notificationsCount > 10 ? "10+" : notificationsCount})
                </span>
              </i>
            }
            size="sm"
            align="end"
            className="me-3"
            variant={notificationsCount > 0 ? "danger" : "secondary"}
          >
            {notificationsCount === 0 && (
              <>
                <Dropdown.Item style={{ cursor: "default" }}>
                  {t("header.no_new_notifications")}
                </Dropdown.Item>
                <Dropdown.Divider />
              </>
            )}
            {notificationsCount > 0 &&
              notifications.map((n, index) => {
                if (index > 9) {
                  // We only display the first 10.
                  return null;
                }
                return (
                  <>
                    <Dropdown.Item style={{ cursor: "default" }}>
                      {n.text}
                    </Dropdown.Item>
                    <Dropdown.Divider />
                  </>
                );
              })}
            <Dropdown.Item onClick={() => navigate("/notifications")}>
              <span className="fw-bold">
                {t("header.show_all_notifications")}
              </span>
            </Dropdown.Item>
          </DropdownButton>

          <DropdownButton
            title={<i className="fa-solid fa-language" />}
            size="sm"
            align="end"
          >
            {AVAILABLE_LANGUAGES.map((language) => {
              return (
                <Dropdown.Item
                  onClick={() => {
                    changeLanguage(language.id);
                  }}
                  key={language.id}
                >
                  {language.name}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
        </div>
      </Container>
    </Navbar>
  );
}

export default DefaultHeader;
